<template>
    <b-modal title="Add product" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <b-form-checkbox v-model="addObject.active">Active</b-form-checkbox>
            </b-form-group>
            <b-form-group>
                <label>Name</label>
                <b-form-input v-model="addObject.name"/>
            </b-form-group>
            <b-form-group>
                <label>Category</label>
                <v-select
                    v-model="addObject.category_id"
                    :reduce="item => item.id"
                    label="name"
                    :options="categories"
                />
            </b-form-group>
            <b-form-group>
                <label>Brand</label>
                <b-form-input v-model="addObject.brand"/>
            </b-form-group>
            <b-form-group>
                <label>Unit</label>
                <b-form-input v-model="addObject.unit"/>
            </b-form-group>
            <b-form-group>
                <label>Quantity multiplier</label>
                <b-form-input :value="addObject.quantity_multiplier" v-on:input="(value) => addObject.quantity_multiplier = Number(value)"/>
            </b-form-group>
            <b-form-group>
                <label>SKU</label>
                <b-form-input v-model="addObject.sku"/>
            </b-form-group>
            <b-form-group>
                <label>Price without VAT</label>
                <b-form-input :value="displayedPrice" v-on:input="changePriceWithoutVAT"/>
            </b-form-group>
            <b-form-group>
                <label>VAT Percentage</label>
                <b-form-input :value="vatPercentage" v-on:input="changeVATPercentage"/>
            </b-form-group>
            <b-form-group>
                <label>VAT Value</label>
                <b-form-input :value="displayedVATValue" v-on:input="changeVATValue"/>
            </b-form-group>
            <ColorPicker v-model="addObject.color"/>

        </template>

        <template #modal-footer>

            <b-button variant="danger" @click="modalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="addProduct">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BFormCheckbox} from 'bootstrap-vue'
    import ColorPicker from '@/views/components/ColorPicker'
    import vSelect from 'vue-select'

    export default {
        props: {
            categories: {
                type: Array,
                required: true
            }
        },
        components: {
            ColorPicker,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BFormCheckbox,
            vSelect
        },
        data() {
            return {

                modalActive: false,
                addObject: {
                    color: '#000000',
                    name: '',
                    brand: '',
                    category_id: '',
                    sku: '',
                    unit: '',
                    quantity_multiplier: 1,
                    price_without_vat: 0,
                    vat: 0,
                    active: true
                },

                displayedPrice: 0,
                displayedVATValue: 0,
                vatPercentage: 0

            }
        },
        methods: {
            open() {
                this.modalActive = true
            },
            addProduct() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/product', this.addObject)
                addPromise.then(function(response) {
                    thisIns.modalActive = false
                    thisIns.$emit('itemAdded', response.data.id)
                    thisIns.$printSuccess('Product added')
                    thisIns.addObject = {
                        color: '#000000',
                        name: '',
                        brand: '',
                        category: thisIns.addObject.category,
                        sku: '',
                        unit: '',
                        quantity_multiplier: 1,
                        price_without_vat: 0,
                        vat: 0
                    }

                    thisIns.displayedPrice = 0
                    thisIns.displayedVATValue = 0
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            changePriceWithoutVAT(val) {
                this.displayedPrice = val
                this.addObject.price_without_vat = Number(Number(val * 100).toFixed(0))
                this.addObject.vat = Number((Number(this.vatPercentage / 100) * this.addObject.price_without_vat).toFixed(0))
                this.displayedVATValue = (this.addObject.vat / 100).toFixed(2)
            },
            changeVATValue(val) {
                this.displayedVATValue = val
                this.addObject.vat = Number(Number(this.displayedVATValue * 100).toFixed(0))
                this.vatPercentage = ((this.addObject.vat / this.addObject.price_without_vat) * 100).toFixed(2)
            },
            changeVATPercentage(val) {
                this.vatPercentage = val
                this.addObject.vat = Number(((Number(this.vatPercentage) / 100) * this.addObject.price_without_vat).toFixed(0))
                this.displayedVATValue = (this.addObject.vat / 100).toFixed(2)
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>